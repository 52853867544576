<template>
  <div class="card">
    <div class="card-header">
      <a @click="toggleExpansion">
        <h4 class="card-title mt-2 mb-0">
          {{ headerTitle }}
          <i class="fa float-right" :class="icon"></i>
        </h4>
      </a>
    </div>
    <div class="card-body" v-if="this.showHistory">
      <div class="row" v-if="history">
        <b-table
          ref="table"
          striped
          :items="history"
          :fields="fields"
          stacked="md"
          small
          bordered
          sort-icon-left
          v-if="history && history.length > 0"
          :primary-key="'index'"
        >
          <template v-slot:cell(date)="data">
            {{ data.item.date | formatDateTime }}
          </template>
          <template v-slot:cell(newValue)="data">
            <b-collapse
              :id="'newValue' + data.index"
              visible
              v-if="data.item.newValue != null"
            >
              <p
                v-html="data.item.newValue.substr(0, showMoreLength)"
                class="my-auto"
              ></p>
            </b-collapse>

            <b-collapse :id="'newValue' + data.index">
              <p v-html="data.item.newValue" class="my-auto"></p>
            </b-collapse>
            <b-button
              v-if="data.item.newValue != null && data.item.newValue.length > showMoreLength"
              v-b-toggle="'newValue' + data.index"
              variant="link"
              ><span class="when-open text-primary">Show less</span
              ><span class="when-closed text-primary"
                >Show more...</span
              ></b-button
            >
          </template>
          <template v-slot:cell(previousValue)="data">
            <b-collapse
              :id="'previousValue' + data.index"
              visible
              v-if="data.item.previousValue != null"
            >
              <p
                v-html="data.item.previousValue.substr(0, showMoreLength)"
                class="my-auto"
              ></p>
            </b-collapse>

            <b-collapse :id="'previousValue' + data.index">
              <p v-html="data.item.previousValue" class="my-auto"></p>
            </b-collapse>
            <b-button
              v-if="
                data.item.previousValue != null &&
                  data.item.previousValue.length > showMoreLength
              "
              v-b-toggle="'previousValue' + data.index"
              variant="link"
              ><span class="when-open text-primary">Show less</span
              ><span class="when-closed text-primary"
                >Show more...</span
              ></b-button
            >
          </template>
        </b-table>
        <p class="mx-auto" v-if="history && history.length === 0">
          No history found
        </p>
      </div>
      <div v-else class="row">
        <span
          class="spinner-border spinner-border-sm mx-auto"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminAuditHistory",
  props: {
    headerTitle: String,
    history: Array
  },
  data() {
    return {
      showHistory: false,
      showMoreLength: 400,
      fields: [
        {
          key: "date",
          label: "Date",
          class: "dateColumn",
          sortable: true
        },
        {
          key: "user",
          label: "User",
          class: "userColumn",
          sortable: true
        },
        {
          key: "fieldChanged",
          label: "Field Changed",
          sortable: true,
          class: "dateColumn"
        },
        {
          key: "previousValue",
          label: "Previous Value",
          sortable: true,
          class: "userColumn"
        },
        {
          key: "newValue",
          label: "New Value",
          sortable: true,
          class: "userColumn"
        }
      ]
    };
  },
  computed: {
    icon() {
      if (this.showHistory) {
        return "fa-angle-down";
      }
      return "fa-angle-up";
    }
  },
  methods: {
    async toggleExpansion() {
      this.showHistory = !this.showHistory;
      if (this.showHistory) {
        await this.getHistory();
      }
    },
    async getHistory() {
      this.$emit("getHistory");
    },
    formatFieldName(fieldName) {
      let fieldNameWithSpaces = fieldName.replace("_", " ");
      return fieldNameWithSpaces.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    }
  }
};
</script>

<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.modal-title {
  font-weight: bold;
}
.dateColumn {
  width: 200px !important;
}
.userColumn {
  width: 400px !important;
}
</style>
